import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/Mondaic/infrastructure/website/src/templates/mdx_template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Studying Ambient Seismic Noise with Salvus: Modeling, Monitoring and Inversion`}</h1>
    <p>{`Numerous studies have shown the great potential of utilizing ambient seismic noise from oceans or anthropogenic sources as a source of information.
Conventional methods rely on cross-correlations of stacked noise observations to extract empirical Green's functions between station pairs.`}</p>
    <p>{`More recently, novel methods have been developed to treat the correlation function itself as an independent observable and to directly model the cross correlation wavefield. Among several theoretical and practical advantages, this approach shows great potential for joint reconstructions of noise sources and Earth structure as well as monitoring applications.`}</p>
    <p>{`This page summarizes the highlights of two case studies using Salvus as modeling engine for studying ambient seismic noise.`}</p>
    <h2>{`Case Study 1: Global-Scale Full-Waveform Ambient Noise Inversion`}</h2>
    <p><a parentName="p" {...{
        "href": "#References"
      }}>{`Sager et al. (2019)`}</a>{` use Salvus to perform a joint full wave-form inversion for 3-D Earth structure and heterogeneous ambient seismic noise sources. By modeling the correlation wavefield, their approach is not limited by the theoretical requirements of Green's function retrieval. They obtain a heterogeneous noise source distribution of the Earth's hum and recover a velocity structure within the upper mantle that shows good agreement with S20RTS. This demonstrates the potential of full-waveform ambient noise inversion and its ability to complement passive data in regions with poor earthquake coverage.`}</p>
    <p><img parentName="p" {...{
        "src": ":/16abecf30f04448d9814e5f73b372aae",
        "alt": "1e2ba43b22743eeaa9c0b018269b8058.png"
      }}></img></p>
    <p><strong parentName="p">{`(left)`}</strong>{` Reconstructed power spectral density distribution of the Earth's hum in Northern Hemisphere winter obtain. Yellow regions thus indicate where the Earth's hum is predominantly generated. `}<strong parentName="p">{`(right)`}</strong>{` Final inversion result for vertical shear velocity at 100 km depth. Figure and caption taken from `}<a parentName="p" {...{
        "href": "https://doi.org/10.1029/2019JB018644"
      }}>{`Sager et al. (2019)`}</a>{` published under `}<a parentName="p" {...{
        "href": "https://creativecommons.org/licenses/by/4.0/"
      }}>{`CC BY 4.0`}</a>{`.`}</p>
    <p><img parentName="p" {...{
        "src": ":/9a181f33f51d4694945ea2a3f9827c17",
        "alt": "f12be1ea14794da25ff0e36c2727a5cb.png"
      }}></img></p>
    <p>{`Evolution of asymmetry and time shift measurements presented in histograms `}<strong parentName="p">{`(top right)`}</strong>{` and illustrated on selected correlation functions `}<strong parentName="p">{`(bottom panel)`}</strong>{`. The station map `}<strong parentName="p">{`(top left)`}</strong>{` shows the reference stations (stars) and receivers (triangles). They are color coded according to the three rows in the bottom panel (row 1: yellow, row 2: purple, row 3: green). The waveform plots focus on the surface wave signals on the causal and the acausal branches and have the part in between removed (indicated with dots). Values for asymmetry differences and time shifts are indicated in the waveform plots, in the center or on the corresponding branches. Red and blue correspond to the initial and final models, respectively, and observed correlations are plotted in black. Figure and caption taken from `}<a parentName="p" {...{
        "href": "https://doi.org/10.1029/2019JB018644"
      }}>{`Sager et al. (2019)`}</a>{` published under `}<a parentName="p" {...{
        "href": "https://creativecommons.org/licenses/by/4.0/"
      }}>{`CC BY 4.0`}</a>{`.`}</p>
    <h2>{`Case Study 2: Fault monitoring using train traffic sources`}</h2>
    <p>{`The second case study by `}<a parentName="p" {...{
        "href": "#References"
      }}>{`Sager et al. (2021)`}</a>{` is about
monitoring the San Jacinto Fault using train induced seismic waves.
The authors use Salvus to model correlations between P-waves as well as their sensitivity to 3-D Earth structure.
Similar to the first case study, considering the correlation wavefield as a self-consistent observable overcomes the requirements of Green's function retrieval. The results of this study pave the way for utilizing the sensitivity of body waves to temporal changes of the subsurface in monitoring applications.`}</p>
    <p><img parentName="p" {...{
        "src": ":/1c184fa7d647451ba4c97deb52fe15f6",
        "alt": "0843bc81357ce8744736c212a93fce8f.png"
      }}></img></p>
    <p>{`The first step in the simulation of a correlation wavefield is to compute the generating wavefield `}<strong parentName="p">{`(top left)`}</strong>{` with a source at `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`x`}</mi><mn parentName="msub">{`2`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`x_2`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.58056em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.30110799999999993em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.5500000000000003em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`2`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{`. In a simple, hypothetical medium two waves are excited: a fast type A and a slow type B. The resulting correlation wavefield `}<strong parentName="p">{`(top right)`}</strong>{` contains all available wavefield interactions: AA, BB, AB, BA. Muting type B in the generating wavefield (indicated with ∗) allows us to focus on the remaining combinations AA and BA. Figure and caption taken from `}<a parentName="p" {...{
        "href": "https://doi.org/10.31223/X5WP6N"
      }}>{`Sager et al. (2021)`}</a>{` published under `}<a parentName="p" {...{
        "href": "https://creativecommons.org/licenses/by/4.0/"
      }}>{`CC BY 4.0`}</a>{`.`}</p>
    <p><img parentName="p" {...{
        "src": ":/8cb989ac49174741be7856d98449db54",
        "alt": "40c6c112314b3e35fc8580183e8dd9ac.png"
      }}></img></p>
    <p>{`Observed (black) and synthetic (red) correlations (filtered between 4 and 6 Hz) are compared for two station pairs: II.PFO-AZ.FRD `}<strong parentName="p">{`[A]`}</strong>{` and II.PFO- AZ.CRY `}<strong parentName="p">{`[C]`}</strong>{`.  The complete data-independent structure sensitivity kernels for measurements of traveltime shifts in the indicated time windows are shown for both station pairs `}<strong parentName="p">{`[B & D]`}</strong>{`. Since II.PFO and AZ.CRY are not perfectly aligned with the train location, the combination of the constituent kernels `}<strong parentName="p">{`[D1 & D2]`}</strong>{` reveals complex patterns. The station locations are shown together with the train location in a map `}<strong parentName="p">{`[E]`}</strong>{`. Station CI.IDO was used to detect the trains.
Figure and caption taken from `}<a parentName="p" {...{
        "href": "https://doi.org/10.31223/X5WP6N"
      }}>{`Sager et al. (2021)`}</a>{` published under `}<a parentName="p" {...{
        "href": "https://creativecommons.org/licenses/by/4.0/"
      }}>{`CC BY 4.0`}</a>{`.`}</p>
    <h2>{`Benefits of Salvus for ambient noise monitoring`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Direct modeling of the correlation wavefield, which serves as independent observable`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Adjoint-based sensitivity analysis with support for various observables, including strain and gradients`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Full-waveform ambient noise inversion for joint reconstruction of ambient noise sources and Earth structure`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Modern Python-based user interface and extendable research platform.`}</p>
      </li>
    </ul>
    <h2>{`References`}</h2>
    <p>{`K. Sager et al. (2021). `}<a parentName="p" {...{
        "href": "https://doi.org/10.31223/X5WP6N"
      }}><em parentName="a">{`Modeling P waves in seismic noise correlations: Application to fault monitoring using train traffic sources.`}</em></a>{` EarthArXiv, submitted to Geophysical Journal International. doi: 10.31223/X5WP6N`}</p>
    <p>{`K. Sager et al. (2020). `}<a parentName="p" {...{
        "href": "https://doi.org/10.1029/2019JB018644"
      }}><em parentName="a">{`Global‐scale full‐waveform ambient noise inversion.`}</em></a>{`
Journal of Geophysical Research: Solid Earth, 125, e2019JB018644.
doi: 10.1029/2019JB018644.`}</p>
    <h3>{`Further reading:`}</h3>
    <p>{`P. Paitz et al. (2019). `}<a parentName="p" {...{
        "href": "https://doi.org/10.1093/gji/ggy528"
      }}><em parentName="a">{`Rotation and strain ambient noise interferometry.`}</em></a>{`
Geophysical Journal International, Volume 216, Issue 3, March 2019, Pages 1938–1952. doi: 10.1093/gji/ggy528.`}</p>
    <p>{`K. Sager et al. (2018). `}<a parentName="p" {...{
        "href": "https://doi.org/10.1029/2018JB016042"
      }}><em parentName="a">{`Sensitivity of seismic noise correlation functions to global noise sources.`}</em></a>{` Journal of Geophysical Research: Solid Earth, 123, Pages 6911– 6921. doi: 10.1029/2018JB016042`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      